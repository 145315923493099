
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


























































































































































.price-box,
[class*='price-box--'] {
  @extend %box-shadow;

  overflow: hidden;
  background-color: $white;
  border-radius: 3px;
}

.price-box__promo {
  @extend %fw-bold;
  @extend %text-uppercase;

  padding: $spacing / 4 $spacing / 2;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.3rem;
  letter-spacing: 1px;
  background-color: $c-orange;
}

.price-box-inner {
  padding: $spacing / 2 $spacing;
}

.price-box__from,
.price-box__prev {
  @extend %fw-medium;
  @extend %text-uppercase;

  color: $gray-medium;
  font-size: 0.8rem;
}

.price-box__from {
  color: $c-blue-pale;
  font-family: $ff-alt;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.price-box__prev {
  position: relative;
  display: table;
  color: $black;
  font-size: 1.2rem;
}

.price-box__prev--crossed {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $black;
    transform: translate(-50%, -50%);
  }
}

.price-box__price {
  display: flex;
  color: $c-pink-medium;
  font-size: 3.2rem;
  line-height: 1;

  > div {
    margin-right: 0.7rem;
  }

  & > small {
    display: flex;
    flex-direction: column;
    margin: 0.3rem 0 0 0.3rem;
    font-size: 1.2rem;

    & :last-child {
      padding-top: 0.2rem;
      color: $gray-medium;
    }

    .tv-extra .hero & {
      flex-direction: row;
      margin: 0;
      font-size: 1.6rem;

      & :last-child {
        padding-top: 0;
        color: $white;
      }
    }
  }

  .tv-extra .hero & {
    color: $white;
    font-size: 1.6rem;
  }
}

.price-box__offer {
  font-family: $ff-alt;
  font-size: 3.2rem;
  font-weight: 900;
}

.price-box__option {
  max-width: 15rem;
}

.price-box__period {
  em {
    @extend %fw-bold;

    color: $c-orange;
    font-style: normal;
  }
}

.price-box__order {
  margin-top: $spacing;

  .btn__icon {
    display: none;
  }
}

.price-box__smaller,
.price-box__bigger {
  display: block;
  color: $c-orange;
  font-size: 18px;
}

.price-box__bigger {
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
}

.price-box-inner.is-normal {
  .price-box__prev.text-tiny,
  .price-box__period.text-tiny {
    font-size: 1.6rem;
  }

  .price-box__price {
    align-items: center;
    color: $c-pink-medium;
    font-size: 4.4rem;

    & > small {
      @extend %fw-normal;

      margin-left: 0.6rem;
      flex-direction: column;
      font-size: 1.8rem;
    }

    .tv-extra .hero & :last-child {
      color: $c-pink-medium;
    }
  }
}
